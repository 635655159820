<template>
  <div class="fixed inset-0 overflow-hidden z-50">
    <div class="absolute inset-0 overflow-hidden">
      <section class="absolute inset-y-0 right-0 pl-10 max-w-full flex sm:pl-16" aria-labelledby="slide-over-heading">
        <div class="w-screen max-w-2xl">
          <div class="h-full flex flex-col bg-white shadow-xl">
            <div class="min-h-0 flex-1 flex flex-col overflow-y-scroll">
              <!-- Header -->
              <div
                class="px-4 py-6 sm:px-6"
                :class="'bg-' + project.color + '-100'"
              >
                <div class="flex items-center justify-between space-x-3">
                  <div class="flex-none">
                    <div
                      class="items-center w-10 h-10 rounded-full"
                      :class="'bg-' + project.color + '-200'"
                    >
                    </div>
                  </div>
                  <div class="space-y-1 flex-grow">
                    <h2 class="text-lg font-medium text-gray-900">
                      {{project.name}}
                    </h2>
                  </div>
                  <div class="h-7 flex-none items-center">
                    <button
                      @click="projectEditStop"
                      class="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500"
                      type="button"
                    >
                      <span class="sr-only">Close panel</span>
                      <!-- Heroicon name: outline/x -->
                      <svg class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
                      </svg>
                    </button>
                  </div>
                </div>
              </div>

              <div class="border-b border-gray-200 mt-6">
                <div class="px-6">
                  <nav class="-mb-px flex space-x-6">
                    <!-- Current: "border-indigo-500 text-indigo-600", Default: "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300" -->
                    <a @click="mode = 'info'"
                       href="#"
                       class="border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300 whitespace-nowrap pb-4 px-1 border-b-2 font-medium text-sm"
                       :class="{'border-indigo-500 text-indigo-600 hover:text-indigo-600 hover:border-indigo-500' : mode === 'info'}"
                    >
                      Info
                    </a>
                  </nav>
                </div>
              </div>

              <!-- Divider container -->
              <div v-if="mode==='info'" class="py-6 space-y-6 sm:py-0 sm:space-y-0 sm:divide-y sm:divide-gray-200">
                <div class="space-y-1 px-4 sm:space-y-0 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 sm:py-5">
                  <div>
                    <label for="project_name" class="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2">
                      Name
                    </label>
                  </div>
                  <div class="sm:col-span-2">
                    <input v-model="name" type="text" name="project_name" id="project_name" class="block w-full shadow-sm sm:text-sm focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md">
                  </div>
                </div>
                <!-- Colors -->
                <div class="space-y-2 px-4 sm:space-y-0 sm:grid sm:grid-cols-3 sm:gap-4 sm:items-center sm:px-6 sm:py-5">
                  <div>
                    <h3 class="text-sm font-medium text-gray-900">
                      Color
                    </h3>
                  </div>
                  <div class="sm:col-span-2">
                      <div v-for="colorOption in colors" v-bind:key="colorOption.value"
                          class="inline-block rounded-full h-10 w-10 m-2 cursor-pointer focus:outline-none focus:ring-2 focus:ring-offset-2 shadow border-white border-2"
                          :class="'bg-' + colorOption.value + '-400 hover:bg-' + colorOption.value + '-700 focus:ring-' + colorOption.value + '-500'"
                          @click="projectChangeColor(colorOption.value)"
                      >
                        <svg v-if="colorOption.value === color" className="inline-block h-6 w-6 rounded-full" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" /></svg>
                      </div>
                  </div>
                </div>
                <div class="space-y-2 px-4 sm:space-y-0 sm:grid sm:grid-cols-3 sm:gap-4 sm:items-center sm:px-6 sm:py-5">
                  <div>
                    <h3 class="text-sm font-medium text-gray-900">
                      Delete
                    </h3>
                  </div>
                  <div class="sm:col-span-2">
                    <p class="text-sm text-gray-600 mb-2">Deleting a project is a permanent action! You will not be able to see and group data based on the deleted project.</p>
                    <button @click="projectDelete" type="button" class="inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md shadow-sm text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500">
                      Delete
                    </button>
                  </div>
                </div>
              </div>

            </div>

            <!-- Action buttons -->
            <div class="flex-shrink-0 px-4 border-t border-gray-200 py-5 sm:px-6">
              <div class="space-x-3 flex justify-end">
                <button @click="projectEditStop" type="button" class="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                  Cancel
                </button>
                <button @click="projectUpdate" type="button" class="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                  Save
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
  import {colors} from '../colors/colors.js';
  import {mapGetters} from "vuex";

  export default {
    name: 'ProjectEdit',
    props: {
      id: String,
      project: Object,
    },
    data() {
      return {
        mode: 'info',
        name: JSON.parse(JSON.stringify(this.project.name)),
        color: JSON.parse(JSON.stringify(this.project.color)),
        colors
      }
    },
    computed: {
      ...mapGetters("auth", ["currentUser"]),
    },
    methods: {
      projectEditStop() {
        this.$emit('projectEditStop');
      },
      projectChangeColor(color) {
        this.color = color;
      },
      projectUpdate() {
        // prepare structure to save
        const data = {};
        if (this.name !== this.project.name) {
          data.name = JSON.parse(JSON.stringify(this.name));
        }
        if (this.color !== this.project.color) {
          data.color = JSON.parse(JSON.stringify(this.color));
        }

        if (Object.keys(data).length) {
          this.$emit('projectUpdate', this.project.refID, this.project.id, data);
        }
        this.projectEditStop();
      },
      projectDelete() {
        this.$emit('projectDelete', this.project.refID, this.project.id);
      }
    }
  }
</script>
